var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "list-demo" } },
    [
      _c("list-basic"),
      _c("list-header"),
      _c("list-icon"),
      _c("list-content"),
      _c("list-avatar"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }