var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Basic", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("A basic list of items with "),
        _c("code", [_vm._v("title")]),
        _vm._v(" and "),
        _c("code", [_vm._v("subtitle")]),
      ]),
      _c(
        "div",
        { staticClass: "demo-alignment" },
        [
          _c(
            "vs-list",
            [
              _c("vs-list-item", { attrs: { title: "One text" } }),
              _c("vs-list-item", {
                attrs: { title: "Another text", subtitle: "A little text" },
              }),
              _c("vs-list-item", { attrs: { title: "Some more text" } }),
              _c("vs-list-item", {
                attrs: {
                  title: "Even more text",
                  subtitle: "Another little text",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<vs-list>\n  <vs-list-item title="One text"></vs-list-item>\n  <vs-list-item title="Another text" subtitle="A little text"></vs-list-item>\n  <vs-list-item title="Some more text"></vs-list-item>\n  <vs-list-item title="Even more text" subtitle="Another little text"></vs-list-item>\n</vs-list>\n      '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }