var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Avatar", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("You can implement an avatar in the list with the "),
        _c("code", [_vm._v('slot="avatar"')]),
        _vm._v(" by putting a [vs-avatar] component"),
      ]),
      _c(
        "vs-alert",
        {
          staticClass: "mt-5",
          attrs: { color: "primary", icon: "new_releases", active: "true" },
        },
        [
          _c("p", [
            _vm._v(
              "Vuesax uses the Google Material Icons font library by default. For a list of all available icons, visit the official "
            ),
            _c(
              "a",
              {
                attrs: { href: "https://material.io/icons/", target: "_blank" },
              },
              [_vm._v("Material Icons page")]
            ),
            _vm._v("."),
          ]),
          _c("p", [
            _vm._v(
              "FontAwesome and other fonts library are supported. Simply use the icon-pack with fa or fas. You still need to include the Font Awesome icons in your project."
            ),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "demo-alignment" },
        [
          _c(
            "vs-list",
            [
              _c("vs-list-header", {
                attrs: { title: "Users", color: "danger" },
              }),
              _c(
                "vs-list-item",
                {
                  attrs: { title: "Steve Jobes", subtitle: "Top Contributor" },
                },
                [_c("template", { slot: "avatar" }, [_c("vs-avatar")], 1)],
                2
              ),
              _c(
                "vs-list-item",
                { attrs: { title: "Matt", subtitle: "11 Points" } },
                [
                  _c(
                    "template",
                    { slot: "avatar" },
                    [_c("vs-avatar", { attrs: { "vs-text": "Vuexy" } })],
                    1
                  ),
                ],
                2
              ),
              _c("vs-list-header", {
                attrs: { title: "Profile photo", color: "warning" },
              }),
              _c(
                "vs-list-item",
                {
                  attrs: {
                    title: "Xian Famous Foods",
                    subtitle:
                      "A taste of Shaanxi's delicious culinary traditions, with delights like spicy cold noodles and lamb burgers.",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "avatar" },
                    [_c("vs-avatar", { attrs: { "vs-text": "Vue" } })],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<vs-list>\n  <vs-list-header title="Users" color="danger"></vs-list-header>\n\n  <vs-list-item title="Steve Jobes" subtitle="Top Contributor">\n    <template slot="avatar">\n      <vs-avatar />\n    </template>\n  </vs-list-item>\n\n  <vs-list-item title="Matt" subtitle="11 Points">\n    <template slot="avatar">\n      <vs-avatar vs-text="Vuexy"/>\n    </template>\n  </vs-list-item>\n\n  <vs-list-header title="Profile photo" color="warning"></vs-list-header>\n\n  <vs-list-item title="Xian Famous Foods" subtitle="A taste of Shaanxi\'s delicious culinary traditions, with delights like spicy cold noodles and lamb burgers.">\n    <template slot="avatar">\n      <vs-avatar vs-text="Vue"/>\n    </template>\n  </vs-list-item>\n</vs-list>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }