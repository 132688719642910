var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Content", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v(
          "You can add custom content to the item. It will be pushed to the right side"
        ),
      ]),
      _c(
        "div",
        { staticClass: "demo-alignment" },
        [
          _c(
            "vs-list",
            [
              _c("vs-list-header", { attrs: { title: "Group 1" } }),
              _c(
                "vs-list-item",
                {
                  attrs: {
                    title: "Snickerdoodle",
                    subtitle: "An excellent companion",
                  },
                },
                [
                  _c("vs-button", { attrs: { color: "danger" } }, [
                    _vm._v("One action"),
                  ]),
                ],
                1
              ),
              _c(
                "vs-list-item",
                {
                  attrs: {
                    title: "Sapporo Haru",
                    subtitle:
                      "An excellent polish restaurant, quick delivery and hearty, filling meals",
                  },
                },
                [_c("vs-checkbox", { attrs: { color: "danger" } })],
                1
              ),
              _c("vs-list-header", {
                attrs: { title: "Group 2", color: "success" },
              }),
              _c(
                "vs-list-item",
                {
                  attrs: {
                    title: "Enid's",
                    subtitle:
                      "At night a bar, during the day a delicious brunch spot.",
                  },
                },
                [
                  _c("vs-chip", { attrs: { color: "warning mr-0" } }, [
                    _vm._v("Another component"),
                  ]),
                ],
                1
              ),
              _c(
                "vs-list-item",
                {
                  attrs: {
                    title: "Veronika Ossi",
                    subtitle: "Has not watched anything recently",
                  },
                },
                [
                  _c("vs-switch", {
                    attrs: { color: "warning" },
                    model: {
                      value: _vm.val,
                      callback: function ($$v) {
                        _vm.val = $$v
                      },
                      expression: "val",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<vs-list>\n  <vs-list-header title="Group 1"></vs-list-header>\n\n  <vs-list-item title="Snickerdoodle" subtitle="An excellent companion">\n    <vs-button color="danger">One action</vs-button>\n  </vs-list-item>\n\n  <vs-list-item title="Sapporo Haru" subtitle="An excellent polish restaurant, quick delivery and hearty, filling meals">\n    <vs-checkbox color="danger"/>\n  </vs-list-item>\n\n  <vs-list-header title="Group 2" color="success"></vs-list-header>\n  <vs-list-item title="Enid\'s" subtitle="At night a bar, during the day a delicious brunch spot.">\n    <vs-chip color="warning">Another component</vs-chip>\n  </vs-list-item>\n\n  <vs-list-item title="Veronika Ossi" subtitle="Has not watched anything recently">\n    <vs-switch color="warning" v-model="val"/>\n  </vs-list-item>\n</vs-list>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }