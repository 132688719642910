var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Header", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("A "),
        _c("code", [_vm._v("vs-list-header")]),
        _vm._v(" separator with custom "),
        _c("code", [_vm._v("color")]),
      ]),
      _c(
        "div",
        { staticClass: "demo-alignment" },
        [
          _c(
            "vs-list",
            [
              _c("vs-list-header", { attrs: { title: "Group 1" } }),
              _c("vs-list-item", {
                attrs: {
                  title: "Snickerdoodle",
                  subtitle: "An excellent companion",
                },
              }),
              _c("vs-list-item", {
                attrs: {
                  title: "Sapporo Haru",
                  subtitle:
                    "An excellent polish restaurant, quick delivery and hearty, filling meals",
                },
              }),
              _c("vs-list-header", {
                attrs: { title: "Group 2", color: "success" },
              }),
              _c("vs-list-item", {
                attrs: {
                  title: "Enid's",
                  subtitle:
                    "At night a bar, during the day a delicious brunch spot.",
                },
              }),
              _c("vs-list-item", {
                attrs: {
                  title: "Veronika Ossi",
                  subtitle: "Has not watched anything recently",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<vs-list>\n  <vs-list-header title="Group 1"></vs-list-header>\n\n  <vs-list-item title="Snickerdoodle" subtitle="An excellent companion"></vs-list-item>\n  <vs-list-item title="Sapporo Haru" subtitle="An excellent polish restaurant, quick delivery and hearty, filling meals"></vs-list-item>\n  <vs-list-header title="Group 2" color="success"></vs-list-header>\n  <vs-list-item title="Enid\'s" subtitle="At night a bar, during the day a delicious brunch spot."></vs-list-item>\n  <vs-list-item title="Veronika Ossi" subtitle="Has not watched anything recently"></vs-list-item>\n</vs-list>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }