var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Icon", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("The list items and the headers can have a "),
        _c("code", [_vm._v("icon")]),
        _vm._v(" as well"),
      ]),
      _c(
        "vs-alert",
        {
          staticClass: "mt-5",
          attrs: { color: "primary", icon: "new_releases", active: "true" },
        },
        [
          _c("p", [
            _vm._v(
              "Vuesax uses the Google Material Icons font library by default. For a list of all available icons, visit the official "
            ),
            _c(
              "a",
              {
                attrs: { href: "https://material.io/icons/", target: "_blank" },
              },
              [_vm._v("Material Icons page")]
            ),
            _vm._v("."),
          ]),
          _c("p", [
            _vm._v(
              "FontAwesome and other fonts library are supported. Simply use the icon-pack with fa or fas. You still need to include the Font Awesome icons in your project."
            ),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "demo-alignment" },
        [
          _c(
            "vs-list",
            [
              _c("vs-list-header", {
                attrs: {
                  "icon-pack": "feather",
                  icon: "icon-users",
                  title: "Group 1",
                },
              }),
              _c("vs-list-item", {
                attrs: {
                  "icon-pack": "feather",
                  icon: "icon-check",
                  title: "Snickerdoodle",
                  subtitle: "An excellent companion",
                },
              }),
              _c("vs-list-item", {
                attrs: {
                  "icon-pack": "feather",
                  icon: "icon-check",
                  title: "Sapporo Haru",
                  subtitle:
                    "An excellent polish restaurant, quick delivery and hearty, filling meals",
                },
              }),
              _c("vs-list-header", {
                attrs: {
                  "icon-pack": "feather",
                  icon: "icon-user-plus",
                  title: "Group 2",
                  color: "success",
                },
              }),
              _c("vs-list-item", {
                attrs: {
                  "icon-pack": "feather",
                  icon: "icon-shield",
                  title: "Enid's",
                  subtitle:
                    "At night a bar, during the day a delicious brunch spot.",
                },
              }),
              _c("vs-list-item", {
                attrs: {
                  "icon-pack": "feather",
                  icon: "icon-shield",
                  title: "Veronika Ossi",
                  subtitle: "Has not watched anything recently",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<vs-list>\n\n    <vs-list-header icon-pack="feather" icon="icon-users" title="Group 1"></vs-list-header>\n    <vs-list-item icon-pack="feather" icon="icon-check" title="Snickerdoodle" subtitle="An excellent companion"></vs-list-item>\n    <vs-list-item icon-pack="feather" icon="icon-check" title="Sapporo Haru" subtitle="An excellent polish restaurant, quick delivery and hearty, filling meals"></vs-list-item>\n\n    <vs-list-header icon-pack="feather" icon="icon-user-plus" title="Group 2" color="success"></vs-list-header>\n    <vs-list-item icon-pack="feather" icon="icon-shield" title="Enid\'s" subtitle="At night a bar, during the day a delicious brunch spot."></vs-list-item>\n    <vs-list-item icon-pack="feather" icon="icon-shield" title="Veronika Ossi" subtitle="Has not watched anything recently"></vs-list-item>\n</vs-list>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }